import { default as indexcFDsXqHHMfMeta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/core/pages/auth/2fa/index.vue?macro=true";
import { default as setupMY3SP0i855Meta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/core/pages/auth/2fa/setup.vue?macro=true";
import { default as invitenbzanheVTvMeta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/core/pages/auth/invite.vue?macro=true";
import { default as loginLMnUNsjKxvMeta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/core/pages/auth/login.vue?macro=true";
import { default as request_45passwordy4AttVWZE8Meta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/core/pages/auth/request-password.vue?macro=true";
import { default as _91token_93MhNYb95qpfMeta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/core/pages/auth/reset-password/[token].vue?macro=true";
import { default as betaaldMPh8eiCRfvMeta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/boekingen/[uuid]/betaald.vue?macro=true";
import { default as factuurQ362Y5gTijMeta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/boekingen/[uuid]/factuur.vue?macro=true";
import { default as indexwHWnVZkpvsMeta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/boekingen/[uuid]/index.vue?macro=true";
import { default as inspectierapportZdnOpGwUwoMeta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/boekingen/[uuid]/inspectierapport.vue?macro=true";
import { default as indexMcsVWwaZpQMeta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/boekingen/[uuid]/wijzigen/index.vue?macro=true";
import { default as _91uuid_93kieSSXzuIzMeta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/boekingen/[uuid].vue?macro=true";
import { default as indexow1koXn6SDMeta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/boekingen/index.vue?macro=true";
import { default as boekingenHrbtyFWuonMeta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/boekingen.vue?macro=true";
import { default as facturenPlZO6aMqTiMeta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/facturen.vue?macro=true";
import { default as indexRRdfObVX1lMeta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/index.vue?macro=true";
import { default as afwijzenrOW6YMAUe3Meta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/offertes/[uuid]/afwijzen.vue?macro=true";
import { default as indexgtDvWv3L1rMeta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/offertes/[uuid]/index.vue?macro=true";
import { default as indexihcu7wGA7mMeta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/offertes/[uuid]/pay/index.vue?macro=true";
import { default as thank_45you9ImKOAKuAqMeta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/offertes/[uuid]/pay/thank-you.vue?macro=true";
import { default as index8OaEbJ7GSQMeta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/offertes/[uuid]/public/index.vue?macro=true";
import { default as wijzigenESCnmRgwY6Meta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/offertes/[uuid]/wijzigen.vue?macro=true";
import { default as _91uuid_938ZilS5rpCCMeta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/offertes/[uuid].vue?macro=true";
import { default as indexD3spXyKYYpMeta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/offertes/index.vue?macro=true";
import { default as offerteszI6GZmaCPAMeta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/offertes.vue?macro=true";
import { default as profieltQwF4YRQubMeta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/profiel.vue?macro=true";
import { default as showcaseUgLvN72pM5Meta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/core/pages/showcase.vue?macro=true";
export default [
  {
    name: "auth-2fa",
    path: "/auth/2fa",
    meta: indexcFDsXqHHMfMeta || {},
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/core/pages/auth/2fa/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-2fa-setup",
    path: "/auth/2fa/setup",
    meta: setupMY3SP0i855Meta || {},
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/core/pages/auth/2fa/setup.vue").then(m => m.default || m)
  },
  {
    name: "auth-invite",
    path: "/auth/invite",
    meta: invitenbzanheVTvMeta || {},
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/core/pages/auth/invite.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginLMnUNsjKxvMeta || {},
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/core/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-request-password",
    path: "/auth/request-password",
    meta: request_45passwordy4AttVWZE8Meta || {},
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/core/pages/auth/request-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-reset-password-token",
    path: "/auth/reset-password/:token()",
    meta: _91token_93MhNYb95qpfMeta || {},
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/core/pages/auth/reset-password/[token].vue").then(m => m.default || m)
  },
  {
    name: boekingenHrbtyFWuonMeta?.name,
    path: "/boekingen",
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/boekingen.vue").then(m => m.default || m),
    children: [
  {
    name: _91uuid_93kieSSXzuIzMeta?.name,
    path: ":uuid()",
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/boekingen/[uuid].vue").then(m => m.default || m),
    children: [
  {
    name: "boekingen-uuid-betaald",
    path: "betaald",
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/boekingen/[uuid]/betaald.vue").then(m => m.default || m)
  },
  {
    name: "boekingen-uuid-factuur",
    path: "factuur",
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/boekingen/[uuid]/factuur.vue").then(m => m.default || m)
  },
  {
    name: "boekingen-uuid",
    path: "",
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/boekingen/[uuid]/index.vue").then(m => m.default || m)
  },
  {
    name: "boekingen-uuid-inspectierapport",
    path: "inspectierapport",
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/boekingen/[uuid]/inspectierapport.vue").then(m => m.default || m)
  },
  {
    name: "boekingen-uuid-wijzigen",
    path: "wijzigen",
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/boekingen/[uuid]/wijzigen/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "boekingen",
    path: "",
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/boekingen/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "facturen",
    path: "/facturen",
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/facturen.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/index.vue").then(m => m.default || m)
  },
  {
    name: offerteszI6GZmaCPAMeta?.name,
    path: "/offertes",
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/offertes.vue").then(m => m.default || m),
    children: [
  {
    name: _91uuid_938ZilS5rpCCMeta?.name,
    path: ":uuid()",
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/offertes/[uuid].vue").then(m => m.default || m),
    children: [
  {
    name: "offertes-uuid-afwijzen",
    path: "afwijzen",
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/offertes/[uuid]/afwijzen.vue").then(m => m.default || m)
  },
  {
    name: "offertes-uuid",
    path: "",
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/offertes/[uuid]/index.vue").then(m => m.default || m)
  },
  {
    name: "offertes-uuid-pay",
    path: "pay",
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/offertes/[uuid]/pay/index.vue").then(m => m.default || m)
  },
  {
    name: "offertes-uuid-pay-thank-you",
    path: "pay/thank-you",
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/offertes/[uuid]/pay/thank-you.vue").then(m => m.default || m)
  },
  {
    name: "offertes-uuid-public",
    path: "public",
    meta: index8OaEbJ7GSQMeta || {},
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/offertes/[uuid]/public/index.vue").then(m => m.default || m)
  },
  {
    name: "offertes-uuid-wijzigen",
    path: "wijzigen",
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/offertes/[uuid]/wijzigen.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "offertes",
    path: "",
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/offertes/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "profiel",
    path: "/profiel",
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/profiel.vue").then(m => m.default || m)
  },
  {
    name: "showcase",
    path: "/showcase",
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/core/pages/showcase.vue").then(m => m.default || m)
  }
]