<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <Notifications
      position="top center"
      class="mt-5"
    >
      <template #body="{item, close}">
        <div
          class="mb-2.5 cursor-pointer rounded-lg bg-primary p-2.5 text-center text-white"
          @click="close"
        >
          <p class="m-0 text-sm">
            {{ item.title }}
          </p>
          <div v-text="item.text" />
        </div>
      </template>
    </Notifications>
  </div>
</template>

<script lang="ts" setup>
const route = useRoute();

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - Jouw GoRoadtrip` : 'Jouw GoRoadtrip';
  },
  title: route.meta?.title,
  lang: 'nl',
});
</script>
