import {nl} from '@formkit/i18n';
import {createConfig, type FormKitNode} from '@formkit/core';
import {generateClasses} from '@formkit/themes';
import {formkitIcons} from './utils/icon';
import {createProPlugin, datepicker, mask, repeater, slider} from '@formkit/pro-unlimited';
import {createInput} from '@formkit/vue';
import {FormkitDrawing} from '#components';

export default createConfig({
  locales: {nl},
  locale: 'nl',
  icons: {
    ...formkitIcons,
  },
  plugins: [
    createProPlugin('fk-cc1ce329d1', {
      repeater,
      slider,
      mask,
      datepicker,
    }),
  ],
  config: {
    classes: generateClasses({
      // Global styles apply to _all_ inputs with matching section keys
      global: {
        fieldset: 'max-w-md border border-gray-400 rounded px-2 pb-1',
        help: 'text-xs text-gray-500',
        inner: 'formkit-disabled:cursor-not-allowed formkit-disabled:pointer-events-none overflow-clip transition hover:ring-primary formkit-disabled:bg-primary-50',
        input: 'leading-tight focus:outline-none focus:ring-0 focus:shadow-none',
        label: 'block mb-1 font-medium text-sm dark:text-gray-light',
        legend: 'mb-1 font-medium text-sm',
        loaderIcon: 'inline-flex items-center w-4 text-gray-600 animate-spin',
        message: 'text-red mb-1 text-xs dark:text-red-400',
        messages: 'list-none p-0 mt-1 mb-0',
        outer: 'mb-4',
        prefixIcon: 'w-7 flex self-stretch grow-0 shrink-0 [&>svg]:w-full [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
        suffixIcon: 'w-7 flex self-stretch grow-0 shrink-0 [&>svg]:w-full [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
      },

      // Family styles apply to all inputs that share a common family
      'family:box': {
        decorator: 'bg-white block relative h-4 w-4 mr-2 rounded-[2px] hover:ring-primary transition cursor-pointer bg-white ring-1 ring-gray-lightest peer-checked:ring-primary peer-checked:bg-primary-500 text-transparent peer-checked:text-primary',
        decoratorIcon: 'flex p-[3px] items-center w-full h-full absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 text-white',
        help: 'mb-2 mt-1.5',
        input: 'absolute w-0 h-0 overflow-hidden opacity-0 pointer-events-none peer',
        inner: '$remove:formkit-disabled:bg-primary-50 overflow-visible',
        label: '$reset text-sm select-none',
        wrapper: 'flex items-center mb-1 formkit-disabled:pointer-events-none',
      },
      'family:button': {
        input: '$reset inline-flex cursor-pointer justify-center gap-1.5 rounded-xl text-sm font-medium no-underline transition disabled:cursor-not-allowed disabled:opacity-30 disabled:saturate-0 print:hidden px-5 pb-2 pt-3 bg-primary-500 hover:bg-primary active:bg-primary-500 active:border-primary-500 text-white border border-primary-700 border-l border-r border-b-4 formkit-disabled:bg-gray-400 formkit-loading:before:w-4 formkit-loading:before:h-4 formkit-loading:before:mr-2 formkit-loading:before:border formkit-loading:before:border-2 formkit-loading:before:border-r-transparent formkit-loading:before:rounded-3xl formkit-loading:before:border-white formkit-loading:before:animate-spin',
        wrapper: 'mb-1',
        prefixIcon: '$reset block w-4 -ml-2 mr-2 stretch',
        suffixIcon: '$reset block w-4 ml-2 stretch',
      },
      'family:dropdown': {
        dropdownWrapper: 'my-2 w-full shadow-lg rounded [&::-webkit-scrollbar]:hidden',
        emptyMessageInner: 'flex items-center justify-center text-sm p-2 text-center w-full text-gray-500 [&>span]:mr-3 [&>span]:ml-0',
        inner: 'bg-white max-w-md relative flex ring-1 ring-gray-lightest focus-within:ring-primary rounded mb-1 formkit-disabled:focus-within:ring-gray-lightest formkit-disabled:focus-within:ring-1 [&>span:first-child]:focus-within:text-primary',
        input: 'w-full px-3 py-2',
        listbox: 'bg-white shadow-lg rounded overflow-hidden',
        listboxButton: 'flex w-12 self-stretch justify-center mx-auto',
        listitem: 'pl-7 relative hover:bg-gray-300 data-[is-active="true"]:bg-gray-300 aria-selected:bg-blue-600 aria-selected:text-white data-[is-active="true"]:aria-selected:bg-blue-600 data-[is-active="true"]:aria-selected:bg-blue-700',
        loaderIcon: 'ml-auto',
        loadMoreInner: 'flex items-center justify-center text-sm p-2 text-center w-full text-primary formkit-loading:text-gray-500 cursor-pointer [&>span]:mr-3 [&>span]:ml-0',
        option: 'p-2.5',
        optionLoading: 'pl-2.5 text-gray-400',
        placeholder: 'p-2.5 text-gray-400',
        selector: 'flex w-full justify-between items-center min-h-[2.625em] [&u] cursor-default',
        selection: 'flex w-full',
        selectedIcon: 'block absolute top-1/2 left-2 w-3 -translate-y-1/2',
        selectIcon: 'flex box-content w-4 px-2 self-stretch grow-0 shrink-0 [&>svg]:w-[1em] cursor-pointer',
      },
      'family:text': {
        inner: 'flex items-center max-w-md ring-1 ring-gray-lightest focus-within:ring-primary [&>label:first-child]:focus-within:text-primary rounded bg-white mb-1',
        input: 'w-full px-2.5 py-3 border-none text-base  placeholder-gray',
      },

      // Specific styles apply only to a given input type
      date: {
        input: '!leading-none',
      },
      datepicker: {
        inner: 'relative has-[input:read-only]:bg-white',
        panelWrapper: 'absolute max-w-[22rem] min-w-[22rem] shadow rounded p-5 bg-white z-10 select-none',
        panelHeader: 'grid grid-cols-[2.5em_1fr_2.5em] justify-center items-center border-b-2 mb-4 pb-4',
        monthsHeader: 'flex items-center justify-center col-start-2 col-end-2',
        timeHeader: 'flex items-center justify-center col-start-2 col-end-2',
        overlayPlaceholder: 'text-gray-400',
        months: 'flex flex-wrap',
        month: `
          flex items-center justify-center
          w-[calc(33%_-_1em)] m-2 p-2 rounded-md
          bg-gray-200
          aria-selected:bg-primary-500 aria-selected:text-white
          focus:outline focus:outline-2 focus:outline-primary-500 focus:outline-offset-2 focus:bg-white focus:text-black
          data-[is-extra=true]:opacity-25
          formkit-disabled:opacity-50 formkit-disabled:cursor-default formkit-disabled:pointer-events-none hover:cursor-pointer
        `,
        yearsHeader: 'flex items-center justify-center col-start-2 col-end-2',
        years: 'flex flex-wrap',
        year: `
          flex items-center justify-center
          w-[calc(20%_-_1em)] m-2 p-2 rounded-md
          bg-gray-200
          aria-selected:bg-primary-500 aria-selected:text-white
          focus:outline focus:outline-2 focus:outline-primary-500 focus:outline-offset-2 focus:bg-white focus:text-black
          data-[is-extra=true]:opacity-25
          formkit-disabled:opacity-50 formkit-disabled:cursor-default formkit-disabled:pointer-events-none hover:cursor-pointer
        `,
        weekDays: 'flex',
        weekDay: 'flex w-[2.25em] h-[1em] m-1 items-center justify-center rounded-md font-medium lowercase',
        week: 'flex formkit-disabled:opacity-50 formkit-disabled:cursor-default formkit-disabled:pointer-events-none',
        dayCell: `
          flex items-center justify-center
          w-[2.25em] h-[2.25em] m-1 p-2 rounded-md
          bg-gray-200
          aria-selected:bg-primary-500 aria-selected:text-white
          focus:outline focus:outline-2 focus:outline-primary-500 focus:outline-offset-2 focus:bg-white focus:text-black
          data-[is-extra=true]:opacity-25
          formkit-disabled:opacity-50 formkit-disabled:cursor-default formkit-disabled:pointer-events-none hover:cursor-pointer
        `,
        timeInput: 'w-full border-2 border-gray-300 rounded-md p-2 my-[2em] focus-visible:outline-primary-500',
        daysHeader: 'flex items-center justify-center',
        prev: 'mr-auto px-3 py-1 hover:bg-gray-100 hover:rounded-lg col-start-1 col-end-1',
        prevLabel: 'hidden',
        prevIcon: 'flex w-3 select-none [&>svg]:w-full',
        dayButton: 'appearance-none cursor-pointer px-3 py-1 border-2 rounded-lg mx-1 hover:border-primary-500',
        monthButton: 'appearance-none cursor-pointer px-3 py-1 border-2 rounded-lg mx-1 hover:border-primary-500',
        yearButton: 'appearance-none cursor-pointer px-3 py-1 border-2 rounded-lg mx-1 hover:border-primary-500',
        next: 'ml-auto px-3 py-1 hover:bg-gray-100 hover:rounded col-start-3 col-end-3',
        nextLabel: 'hidden',
        nextIcon: 'flex w-3 select-none [&>svg]:w-full',
        openButton: `
          appearance-none border-0 bg-transparent flex p-0 self-stretch cursor-pointer
          focus-visible:outline-none focus-visible:text-white focus-visible:bg-primary-500
        `,
        calendarIcon: 'flex w-8 grow-0 shrink-0 self-stretch select-none [&>svg]:w-full [&>svg]:m-auto [&>svg]:max-h-[1em] [&>svg]:max-w-[1em]',
      },
      color: {
        inner: 'flex max-w-[5.5em] w-full formkit-prefix-icon:max-w-[7.5em] formkit-suffix-icon:formkit-prefix-icon:max-w-[10em]',
        input: '$reset appearance-none w-full cursor-pointer border-none rounded p-0 m-0 bg-transparent [&::-webkit-color-swatch-wrapper]:p-0 [&::-webkit-color-swatch]:border-none',
        suffixIcon: 'min-w-[2.5em] pr-0 pl-0 m-auto',
      },
      file: {
        fileItem: 'flex items-center text-gray-800 mb-1 last:mb-0',
        fileItemIcon: 'w-4 mr-2 shrink-0',
        fileList: 'shrink grow peer px-3 py-2 formkit-multiple:data-[has-multiple="true"]:mb-6',
        fileName: 'break-all grow text-ellipsis',
        fileRemove: 'relative z-[2] ml-auto text-[0px] hover:text-red pl-2 peer-data-[has-multiple=true]:text-sm peer-data-[has-multiple=true]:text-primary peer-data-[has-multiple=true]:ml-3 peer-data-[has-multiple=true]:mb-2 formkit-multiple:bottom-[0.15em] formkit-multiple:pl-0 formkit-multiple:ml-0 formkit-multiple:left-[1em] formkit-multiple:formkit-prefix-icon:left-[3.75em]',
        fileRemoveIcon: 'block text-base w-3 relative z-[2]',
        inner: 'relative max-w-md cursor-pointer formkit-multiple:[&>button]:absolute',
        input: 'cursor-pointer text-transparent absolute top-0 right-0 left-0 bottom-0 opacity-0 z-[2]',
        noFiles: 'flex w-full items-center px-3 py-2 text-gray-400',
        noFilesIcon: 'w-4 mr-2',
      },
      radio: {
        decorator: 'rounded-full',
        decoratorIcon: '$reset w-5',
      },
      // range: {
      //   inner: '$reset flex items-center max-w-md',
      //   input: '$reset w-full mb-1 h-2 p-0 rounded-full',
      //   prefixIcon: '$reset w-4 mr-1 flex self-stretch grow-0 shrink-0 [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
      //   suffixIcon: '$reset w-4 ml-1 flex self-stretch grow-0 shrink-0 [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
      // },
      select: {
        inner: 'flex relative max-w-md items-center rounded mb-1 ring-1 ring-gray-lightest focus-within:ring-primary [&>span:first-child]:focus-within:text-primary',
        input: 'appearance-none formkit-disabled:bg-primary-50 w-full pl-2.5 pr-5 py-3 text-base placeholder-gray formkit-multiple:p-0 data-[placeholder="true"]:text-primary formkit-multiple:data-[placeholder="true"]:text-inherit border-r-4 border-transparent',
        selectIcon: 'flex p-[3px] shrink-0 w-5 -ml-[1.5em] h-full pointer-events-none [&>svg]:w-[1em]',
        option: 'formkit-multiple:p-3 formkit-multiple:text-sm text-gray-700',
      },
      textarea: {
        inner: 'flex max-w-md rounded mb-1 ring-1 ring-gray-lightest focus-within:ring-primary [&>label:first-child]:focus-within:text-primary',
        input: 'block w-full h-32 px-3 py-3 border-none text-base placeholder-gray focus:shadow-outline',
      },
    }),
  },
  inputs: {
    drawing: createInput(FormkitDrawing, {
      family: 'text',
      props: ['image', 'drawing-class', 'width', 'height', 'border'],
    }),
  },
  rules: {
    maxFileSize: (node: FormKitNode & { value: { file: File }[] }, args: string[]) => {
      const size = +args[0] * 1024 * 1024;
      return node.value[0].file.size <= size;
    },
  },
  messages: {
    nl: {
      validation: {
        maxFileSize({name, args}: { name: string, args: string[], node: FormKitNode }) {
          const size = +args[0] / 1024;
          return `${name} mag niet groter zijn dan ${size} MB.`;
        },
      },
    },
  },
});
