<template>
  <div
    class="flex w-max select-none items-center justify-center gap-1 rounded border font-medium leading-none"
    :class="
      [
        colorVariations[color],
        size === 'small' ? 'p-1.5 text-xs' : 'p-2 text-sm',
      ]"
  >
    <UiIcon
      v-if="icon"
      size="extra-small"
      :name="icon"
    />
    {{ label }}
  </div>
</template>

<script lang="ts" setup>
export type Color = 'blue' | 'green' | 'purple' | 'red' | 'white' | 'yellow'
export type TagProps = {
  label: string
  color?: Color
  icon?: string
  size?: 'small'
}

withDefaults(defineProps<TagProps>(), {
  color: 'green',
  icon: undefined,
  size: undefined,
});

const colorVariations = {
  blue: 'bg-primary-100 border-primary-200 text-primary-300',
  green: 'bg-green-light border-green text-green-dark',
  purple: 'bg-purple-light border-purple text-purple-dark',
  red: 'bg-red-light border-red text-red-dark',
  white: 'bg-white border-gray-light text-gray-dark',
  yellow: 'bg-secondary-light border-secondary text-secondary-dark',
};
</script>
