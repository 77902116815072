import validate from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.5.0_@types+node@22.8.7_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_jt4eplcvsydlr7tpevo2rznze4/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45trailing_45slash_45global from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.1.0_@bugsnag+core@7.25.0_magicast@0.3.5_nuxt@3.12.4_@parcel+watc_phcq74ikixhguftsvfggkspm3a/node_modules/@bttr-devs/nuxt-layer-base/middleware/redirect-trailing-slash.global.ts";
import manifest_45route_45rule from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.5.0_@types+node@22.8.7_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_jt4eplcvsydlr7tpevo2rznze4/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "sofie-page": () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.4.1_@bugsnag+core@7.25.0_graphql-ws@5.16.0_graphql@16.9.0__magi_anujveftxfpdsajbooaphkkbri/node_modules/@bttr-devs/nuxt-layer-sofie/middleware/sofie-page.ts"),
  auth: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/node_modules/.pnpm/@sidebase+nuxt-auth@0.9.4_magicast@0.3.5_next-auth@4.21.1_next@13.5.6_@babel+core@7.24.9_reac_pmai42ib44veazf2dayy23yeta/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.js")
}